<template>
  <div class="welcome">欢迎使用捷思联想检验智能管控系统</div>
</template>

<script>
export default {
  name: "Welcome"
}
</script>

<style scoped>
.welcome{
  display: flex;
  align-items: center;
  font-size: 46px;
  height: 100%;
  justify-content: center;
  text-shadow: 2px 5px 5px grey;
}
</style>
